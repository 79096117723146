/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect } from "react";

import { Redirect, Link } from "react-router-dom";

import { getCourses, getResources } from "../services/ServerInterface";

import { useIntercom } from "react-use-intercom";

import Popup from "reactjs-popup";

import NotFound from "./404";
import Header from "../components/Header";
import Tag from "../components/Tag";
import Feedback from "../components/Feedback";

import despensas from "../images/logo_1.png";

import logoEmpresarial from '../assets/Assets_Empresarial/logo_2.png';
import menuTitle from '../assets/Assets_Empresarial/menu_title_2_01.png';
import generateDiploma from '../assets/Assets_Empresarial/button_2_02.png';

import practices from '../images/ic_hand_ipad.png';
import feedback_trigger_connect from '../images/feedback_trigger_vale.png'
// import feedback_trigger_ticket from '../images/feedback_trigger_ticketcar.png'

import ticket_car from "../images/ic_ticket_car.svg";
import ticket_car_vr from "../images/ic_ticket_car_vr.svg";
import ecovale from "../images/ic_ecovale.png";
import fleet from "../images/TituloTicketCarNew.svg";
import connect from "../images/ic_connect.png";
import empresarial from "../images/ic_empresarial.svg";
import scann from "../images/ic_scann.png";
import mantenimiento from "../images/ic_mantenimiento_logo.png";

import check from "../images/ic_check.svg";

import card_management from "../images/ic_hands_keyboard.png";
import administrative_procedures from "../images/ic_hand_download.png";
import connect_module_logo from "../images/connect_module_logo.png";
import feedback_trigger_regular from "../images/button_2_02.png";
import { options } from "../pdf/pdfBuilder.js";

import { SECTION_CODES, MODULES_CODES } from "../constants/sections";

const COURSE = "b";

class Modules extends Component {
  constructor(props) {
    super(props);
    this.popup_ref = React.createRef();

    this.state = {
      token: localStorage.getItem("token"),
      section: this.props.match.params.section,
      module: this.props.match.params.module,
      user: JSON.parse(localStorage.getItem("user")),
      items: [],
    };

    let moduleCode = MODULES_CODES[this.state.module];
    let sectionCode = SECTION_CODES[this.state.section];

    if (this.state.module === "administrative_procedures") {
      getResources(this.state.token).then((response) => {
        if (response.ok) {
          var items = [];

          response.body.forEach((item) => {
            if (item.section === sectionCode) {
              if (this.state.section === "scann") {
                if (item.is_scann) {
                  items.push(item);
                }
              } else {
                if (!item.is_scann) {
                  items.push(item);
                }
              }
            }
          });

          items.sort((a, b) => (a.name > b.name ? 1 : -1));

          this.setState({ items });
        } else if (response.status === 401) {
          this.setState({ token: null });
        } else {
          // TODO: Handle server error
        }
      });
    } else {
      getCourses(this.state.token).then((response) => {
        if (response.ok) {
          var items = [];

          response.body.forEach((item) => {
            if (item.module === moduleCode && item.section === sectionCode) {
              items.push(item);
            }
          });

          items.sort((a, b) => (a.name > b.name ? 1 : -1));

          this.setState({ items });
        } else if (response.status === 401) {
          this.setState({ token: null });
        } else {
          // TODO: Handle server error
        }
      });
    }

    this.getBackgroundImage = this.getBackgroundImage.bind(this);
    this.getTagImage = this.getTagImage.bind(this);
    this.getCheck = this.getCheck.bind(this);
    this.getFeedbackModal = this.getFeedbackModal.bind(this);
  }

  getFeedbackModal() {
    let trigger = feedback_trigger_regular;

    if (this.state.section === "connect") {
      trigger = feedback_trigger_connect;
    }

    if (this.state.module === "card_management") {
      return (
        <div className="modal_pop">
          <Popup
            modal
            // eslint-disable-next-line
            trigger={
              <a href="/#">
                {" "}
                <img
                  className="modal_trigger"
                  alt="modal trigger"
                  src={trigger}
                />{" "}
              </a>
            }
            ref={this.popup_ref}
          >
            {(close) => (
              <div className="feedback_modal fadeIn">
                <button className="close_feedback" onClick={close}>
                  &times;
                </button>
                <Feedback parent_ref={this.popup_ref}></Feedback>
              </div>
            )}
          </Popup>
        </div>
      );
    }
  }

  getTagImage() {
    if (this.state.section === "despensas") {
      return despensas;
    } else if (this.state.section === "ticket_car") {
      return ticket_car;
    } else if (this.state.section === "ticket_car_vr") {
      return ticket_car_vr;
    } else if (this.state.section === "ecovale") {
      return ecovale;
    } else if (this.state.section === "fleet") {
      return fleet;
    } else if (this.state.section === "connect") {
      return connect;
    } else if (this.state.section === "empresarial") {
      return empresarial;
    } else if (this.state.section === "scann") {
      return scann;
    } else if (this.state.section === "mantenimiento") {
      return mantenimiento;
    }

    return undefined;
  }

  getBackgroundImage() {
    if (this.state.module === "card_management") {
      return card_management;
    } else if (this.state.module === "practices") {
      return practices;
    } else if (this.state.module === "administrative_procedures") {
      return administrative_procedures;
    }

    return undefined;
  }

  getCheck(isFinished) {
    if (isFinished) {
      return <img className="modules_item_check" src={check} alt="check" />;
    }
  }

  allCourseFinished() {
    let finished = true;
    let itemsList = this.state.items;
    if (itemsList.length <= 0) {
      finished &&= false;
    }
    for (let i = 0; i < itemsList.length; i++) {
      const item = itemsList[i];
      if (
        item.module === COURSE &&
        item.stats != null &&
        item.stats.status === 4
      ) {
        finished &&= true;
      } else {
        finished &&= false;
      }
    }

    return finished;
  }

  createDiploma = () => {
    let userName = this.state.user.first_name + " " + this.state.user.last_name;
    options.exportDiplomaPDF(userName, this.state.section);
  };

  render() {
    if (!this.state.token) {
      return <Redirect to={"/logout/"} />;
    }

    let module = "";
    let isCourse = true;

    if (this.state.module === "card_management") {
      module = "Autogestión de Tarjetas";
      if (this.state.section === "connect") {
        module = "Autogestión";
      }
      if (this.state.section === "fleet") {
        module = "Autogestión de TicketCar+";
      }
    } else if (this.state.module === "practices") {
      module = "Prácticas";
    } else if (this.state.module === "administrative_procedures") {
      module = "Biblioteca de Trámites Administrativos";

      if (this.state.section === "scann") {
        module = "Recursos Multimedia";
      }
      if (this.state.section === "fleet") {
        module = "Biblioteca de medios";
      }
      isCourse = false;
    } else {
      return <NotFound />;
    }

    const items = this.state.items.map((item) => {
      let name = item.name;
      let uuid = isCourse ? item.course_id : item.resource_id;
      let url = (isCourse ? "/course/" : "/resource/") + uuid + "/";

      let isFinished =
        isCourse && item.stats != null && item.stats.status === 4;

      return (
        <li className="modules_item" key={uuid}>
          <Link className="modules_item_link" to={url}>
            {name}
          </Link>
          {this.getCheck(isFinished)}
        </li>
      );
    });

    let backgroundClass = "modules_background";

    if (this.getBackgroundImage() === administrative_procedures) {
      backgroundClass += " vertical";
    }

    const printButton = () => {
      if (this.allCourseFinished()) {
        return (
          <li className="li_buttom">
            <div
              className="diploma_button_wrapper"
              onClick={this.createDiploma}
            >
              Generar diploma
            </div>
          </li>
        );
      }
    };

    if (this.state.section === "ticket_car") {
      return (
        <>
          <Header card_activity={true} />
          <div className="ticket_car_modules_container">
            <img
              className="ticket_car_logo"
              src={this.getTagImage()}
              alt="ticket_car"
            />
            <div className="modules_container">
              <header className="ticket_car_header">
                <h3 className="ticket_car_title">{module}</h3>
              </header>
              <ul className="modules_list">
                {items}
                {printButton()}
              </ul>
            </div>
            {this.getFeedbackModal()}
          </div>
        </>
      );
    }

    if (this.state.section === "despensas") {
      return (
        <div>
          <Header card_activity={true} />
          <div className="despensas_modules_container">
            <FunctionalComponent />
            <div className="despensas_container_form">
              <img
                className="despensas_logo"
                src={this.getTagImage()}
                alt="despensas"
              />
              <div className="modules_container">
                <header className="despensas_header">
                  <h3 className="despensas_title">{module}</h3>
                </header>
                <ul className="modules_list">
                  {items}
                  {printButton()}
                </ul>
              </div>
              {this.getFeedbackModal()}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.section === "mantenimiento") {
      return (
        <>
          <Header card_activity={true} />
          <div className="mantenimiento_modules_outer_container">
            <div className="mantenimiento_modules_container">
              <img
                className="mantenimiento_logo"
                src={this.getTagImage()}
                alt="mantenimiento_logo"
              />
              <div className="modules_container">
                <header className="mantenimiento_header">
                  <h3 className="mantenimiento_title">
                    Gestión de Solicitudes
                  </h3>
                </header>
                <ul className="modules_list">
                  {items}
                  {printButton()}
                </ul>
              </div>
              {this.getFeedbackModal()}
            </div>
          </div>
        </>
      );
    }

    // if (this.state.section === "ecovale") {
    //   return (
    //     <>
    //       <Header card_activity={true} />
    //       <div className="ecovale_modules_container">
    //         <img
    //           className="ecovale_logo"
    //           src={this.getTagImage()}
    //           alt="ecovale logo"
    //         />
    //         <div className="modules_container">
    //           <header className="ecovale_header">
    //             <h3 className="ecovale_title">{module}</h3>
    //           </header>
    //           <ul className="modules_list">
    //             {items}
    //             {printButton()}
    //           </ul>
    //         </div>
    //         {this.getFeedbackModal()}
    //       </div>
    //     </>
    //   );
    // }
    if (this.state.section === "fleet") {
      return (
        <>
          <Header card_activity={true} />
          <div className="fleet_modules_container">
            <img
              className="fleet_logo"
              src={this.getTagImage()}
              alt="fleet logo"
            />
            <div className="modules_container">
              <header className="fleet_header">
                <h3 className="fleet_title">{module}</h3>
              </header>
              <ul className="modules_list">
                {items}
                {printButton()}
              </ul>
            </div>
            {this.getFeedbackModal()}
          </div>
        </>
      );
    }
    if (this.state.section === "connect") {
      return (
        <>
          <Header card_activity={true} />
          <div className="connect_modules_container">
            <img
              className="connect_logo"
              src={this.getTagImage()}
              alt="connect"
            />
            <div className="modules_container">
              <header className="connect_header">
                <h3 className="connect_title">{module}</h3>
              </header>
              <ul className="modules_list">
                {items}
                {printButton()}
              </ul>
            </div>

            <div className="connect_module_logo_container">
              <img
                className="connect_module_logo_image"
                src={connect_module_logo}
                alt="connect_button"
              />
            </div>
            {this.getFeedbackModal()}
          </div>
        </>
      );
    }

    if (this.state.section === "scann") {
      return (
        <>
          <Header card_activity={true} />
          <div className="connect_modules_container">
            <img
              className="connect_logo"
              src={this.getTagImage()}
              alt="connect"
            />
            <div className="modules_container">
              <header className="connect_header">
                <h3 className="connect_title">{module}</h3>
              </header>
              <ul className="modules_list">{items}</ul>
            </div>

            {this.getFeedbackModal()}
          </div>
        </>
      );
    }

    if (this.state.section === "empresarial") {
      return (
        <>
          <Header card_activity={true} />

          <div className="modules_empresarial">
            <div className="module_content">
              <img className='logo_empresarial' src={logoEmpresarial} alt='Logo Empresarial Fintech Edenred' />
              <img className='title_card_mng' src={menuTitle} alt='Autogestión de tarjetas' />
              <ul className="modules_list">
                {items}
              </ul>
              <img className='button_card_mng' src={generateDiploma} alt='Generar Diploma' onClick={this.createDiploma} style={{ visibility: this.allCourseFinished() ? '' : 'hidden' }} />
            </div>
          </div>
        </>
      )
    }

    return (
      <React.Fragment>
        <Header card_activity={true} />

        <div className="modules">
          <div className="modules_content">
            <Tag color="white" src={this.getTagImage()} header={true} />

            <div className="modules_container">
              <header className="modules_header">
                <h3 className="modules_title">{module}</h3>
              </header>

              <ul className="modules_list">{items}</ul>
            </div>

            <img
              className={backgroundClass}
              src={this.getBackgroundImage()}
              alt="background"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function FunctionalComponent() {
  const { shutdown } = useIntercom();
  useEffect(() => {
    shutdown()
  }, [])



  return (<>
  </>
    // JSX for the functional component
  );
}


export default Modules;
