import React, { Component } from 'react';
import { Link } from "react-router-dom";

import NotFound from './404';
import Header from '../components/Header';
// import Tag from '../components/Tag';

// import background from '../images/ic_plane.png';

import despensas from '../images/ic_despensas.svg';
import ticket_car from '../images/ic_ticket_car.svg';
import ticket_car_vr from '../images/ic_ticket_car_vr.svg';
// import ecovale from '../images/ic_ecovale.svg';
import fleet from '../images/TituloTicketCarNew.svg';
import connect from '../images/ic_mantenimiento.svg';
import empresarial from '../images/ic_empresarial.svg';
import mantenimiento from '../images/ic_mantenimiento_logo.png';


import ticket_car_splash from '../images/tc_guest_splash.png'
import ticket_car_header from '../images/tc_guest_header.png'

import despensas_splash from '../images/despensas_guest_splash.png';
import despensas_header from '../images/despensas_guest_header.png';

import empresarial_splash from '../images/empresarial_guest_splash.png';
import empresarial_guest_bg_asset from '../images/empresarial_guest_bg_asset.png';

import fleet_splash from '../images/Imagen Ticket Car +.svg'

import mantenimiento_splash from '../images/mantenimiento_guest_splash.png';

const sections = [
  "despensas",
  "ticket_car",
  "ticket_car_vr",
  "fleet",
  "connect",
  "empresarial",
  "mantenimiento",
]

const SECTIONS_IMAGE = {
  "despensas": despensas,
  "ticket_car": ticket_car,
  "ticket_car_vr": ticket_car_vr,
  "fleet": fleet,
  "connect": connect,
  "empresarial": empresarial,
  "mantenimiento": mantenimiento
}

const SECTION_SPLASH = {
  "ticket_car": ticket_car_splash,
  "despensas": despensas_splash,
  "empresarial": empresarial_splash,
  "fleet": fleet_splash,
  "mantenimiento": mantenimiento_splash
}

const SECTION_HEADER = {
  "ticket_car": ticket_car_header,
  "despensas": despensas_header
}


class Guest extends Component {

  constructor(props) {
    super(props);

    this.state = {

      section: this.props.match.params.section,
      form: true,

    }

    this.getGuestContent = this.getGuestContent.bind(this)
    this.getSectionImage = this.getSectionImage.bind(this)

  }

  componentDidMount() {

  }

  getSectionImage() {
    return SECTIONS_IMAGE[this.state.section]
  }

  getSectionSplash() {
    return SECTION_SPLASH[this.state.section]
  }

  getSectionHeader() {
    return SECTION_HEADER[this.state.section]
  }


  getGuestContent() {

    let section = this.props.match.params.section
    var isValid = false

    sections.forEach(item => {
      if (item === section) {
        isValid = true
      }
    })

    if (!isValid) {
      return <NotFound />
    }

    if (this.state.section === "mantenimiento") {
      return (
        <div className="guest_content_mantenimiento">
          <div className="guest_element_container">

            <div className="guest_form_container">
              <div className="guest_right_wrapper">
                <div className="guest_image_wrapper">
                  <img src={this.getSectionImage()} alt=""></img>
                </div>
                <div className="guest_form">

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/edentour/"}>Edentour</Link>
                  </div>

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/library/"}>Biblioteca de medios</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="guest_splash_wrapper">
              <div className="guest_splash_image_wrapper">
                <img src={this.getSectionHeader()} alt=""></img>
              </div>
              <div className="guest_splash_image_wrapper">
                <img src={this.getSectionSplash()} alt=""></img>
              </div>
            </div>

          </div>
        </div>
      )
    }

    if (this.state.section === "ticket_car") {
      return (

        <div className="guest_content_ticket">
          <div className="guest_element_container">
            <div className="guest_splash_wrapper">
              <div className="guest_splash_image_wrapper">
                <img src={this.getSectionHeader()} alt=""></img>
              </div>
              <div className="guest_splash_image_wrapper">
                <img src={this.getSectionSplash()} alt=""></img>
              </div>
            </div>
            <div className="guest_form_container">
              <div className="guest_right_wrapper">
                <div className="guest_image_wrapper">
                  <img src={this.getSectionImage()} alt=""></img>
                </div>
                <div className="guest_form">

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/edentour/"}>Edentour</Link>
                  </div>

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/library/"}>Biblioteca de medios</Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      )
    }


    if (this.state.section === "fleet") {
      return (

        <div className="guest_content_fleet">
          <div className="guest_element_container">
            <div className="guest_form_container">
              <div className="guest_right_wrapper">
                <div className="guest_image_wrapper_fleet">
                  <img src={this.getSectionImage()} alt=""></img>
                </div>
                <div className="guest_form">

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/edentour/"}>Edentour</Link>
                  </div>

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/library/"}>Biblioteca de medios</Link>
                  </div>

                </div>
              </div>
            </div>

            <div className="guest_splash_wrapper">
              <div className="guest_splash_image_wrapper">
                <img src={this.getSectionSplash()} alt=""></img>
              </div>
            </div>

          </div>
        </div>

      )
    }

    if (this.state.section === "despensas") {
      return (

        <div className="guest_content_despensas">
          <div className="guest_element_container">

            <div className="guest_form_container">
              <div className="guest_right_wrapper">
                <div className="guest_image_wrapper">
                  <img src={this.getSectionImage()} alt=""></img>
                </div>
                <div className="guest_form">

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/edentour/"}>Edentour</Link>
                  </div>

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/library/"}>Biblioteca de medios</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="guest_splash_wrapper">
              <div className="guest_splash_image_wrapper">
                <img src={this.getSectionSplash()} alt=""></img>
              </div>
            </div>

          </div>
        </div>

      )
    }

    if (this.state.section === "empresarial") {
      return (

        <div className="guest_content_empresarial">
          <div className="guest_element_container">

            <div className="guest_form_container">
              <div className="guest_right_wrapper">
                <div className="guest_image_wrapper">
                  <img src={this.getSectionImage()} alt=""></img>
                </div>
                <div className="guest_form">

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/edentour/"}>Edentour</Link>
                  </div>

                  <div className="guest_button_wrapper">
                    <Link className="guest_button" to={"/guest/" + section + "/library/"}>Biblioteca de medios</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="guest_splash_wrapper">
              <div className="guest_splash_image_wrapper">
                <img src={this.getSectionSplash()} alt=""></img>
              </div>
            </div>

          </div>
          <div className="guest_bg_asset">
            <img src={empresarial_guest_bg_asset} alt=""></img>
          </div>
        </div>

      )
    }
  }



  render() {

    return (

      <React.Fragment>

        <Header />

        <div className="guest">

          {this.getGuestContent()}

        </div>

      </React.Fragment>

    )

  }

}

export default Guest;
